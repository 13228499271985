.App {
  text-align: center;
  
}

.eye-lid {
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 9px 0 2px rgba(0, 0, 0, 0.2);
  width: 150px;
  height: 150px;
}

.eye {
  background-color: #ffbc03;
  border-radius: 50%;
  transform: translate3d(0, 0, 0) rotate(90deg);
  width: 120px;
  height: 120px;
  animation: eye 5s infinite;
}

@keyframes eye {

  12%,
  25% {
    width: 100px;
    height: 110px;
  }

  37%,
  50% {
    width: 60px;
    height: 130px;
  }

  63%,
  75% {
    width: 100px;
    height: 103px;
  }

  87% {
    width: 100px;
    height: 100px;
  }
}

.cornea {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: eye-color 5s infinite;
}

@keyframes eye-color {

  63%,
  75% {
    background-color: rgb(8, 20, 96);
  }
}

.white-pupil {
  position: absolute;
  top: 9%;
  left: 10%;
  border-radius: 50%;
  background-color: #ffbc03;
  width: 20px;
  height: 20px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.app_body {
  position: absolute !important;
  top: 0;
  right: 0;
  min-height: 100vh;
}
.home_tap{
  height: 100vh;
  width: 100vw;
}
@font-face {
  font-family: 'Libre Baskerville';
  src: url('./Fonts/Rubik-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Libre Baskerville';
  src: url('./Fonts/Rubik-Italic.ttf') format('ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Libre Baskerville';
  src: url('./Fonts/Rubik-Bold.ttf') format('ttf');
  font-weight: bold;
  font-style: normal;
}
/* 
.cssbuttons-io {
  position: relative;
  font-family: inherit;
  font-weight: 500;
  letter-spacing: 0.05em;
  border-radius: 0.8em;
  cursor: pointer;
  border: none;
  background: linear-gradient(to right, #768A96, #44576D);
  color: #000;
  overflow: hidden;
}

.cssbuttons-io span {
  position: relative;
  z-index: 10;
  transition: color 0.4s;
  display: inline-flex;
  align-items: center;
}

.cssbuttons-io::before,
.cssbuttons-io::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.cssbuttons-io::before {
  content: "";
  background: linear-gradient(to right,#E6E6E6 ,#768A96);
  width: 120%;
  left: -10%;
  transform: skew(30deg);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.cssbuttons-io:hover::before {
  transform: translate3d(100%, 0, 0);
}

.cssbuttons-io:active {
  transform: scale(0.95);
} */
.sec-vide {
  background: #000;
}
.cta {
  border: none;
  background: none;
  cursor: pointer;
}

.cta span {
  letter-spacing: 4px;
  text-transform: uppercase;
}

.hover-underline-animation {
  position: relative;
  color: #DFEBF6;
  padding-bottom: 20px;
  font-family: 'Libre Baskerville';
}

.hover-underline-animation:after{
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #DFEBF6;
  transform-origin: bottom right;
  transition: transform 1s ease-out;
}

.cta:hover .hover-underline-animation:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.cta.active .hover-underline-animation:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.header-sec{
  height: 90vh;
  /* background: url('https: //ucarecdn.com/4ee122a2-5001-4af0-bf0e-b0ef9882bdd8/-/preview/750x1000/');
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat; */

}
.header-sec-text{
  text-transform: uppercase;
  margin-left: 20vw;
  font-family:'Libre Baskerville';
  color: #000;
  line-height: 2em;
}
.paragraph{
  text-transform: uppercase;
    margin-left: 20vw;
    font-family: 'Libre Baskerville';
    color: #000;
}
.header-sec-text-lg{
  font-size: 80px;

}
.header-sec-text-sm{
  font-size: 30px;
}
.disknav{
  background: black;
}
.fcard-text-span{
  text-transform: uppercase;
  font-family: 'Libre Baskerville';
  color: #000;
  background: linear-gradient(to right, #44576D, #768A96);
}
/* .header-sec-text p{
  background: linear-gradient(to top, #C4982A, #000) ;
} */
.header-sec-text div{
  background: linear-gradient(to right, #C4982A, #000);
}
.border-gold{
  border: 1px solid #C4982A !important;
}
.header-sec-text-span{
  color:#C4982A;
  font-weight: bold;
}
.sec-1-h1{
  color: #DFEBF6;
}
.sec-2-h1{
  color: #C4982A;
}
.filter-button{
  font-family: 'Libre Baskerville';
  font-weight: bold;
  letter-spacing: 2px;
}
.sec-2-con{
  background: linear-gradient(to bottom, #000,#3b3b3b, #fff);
}
.fcard {
  overflow: visible;
  min-height:300px;
}

.fcontent {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 300ms;
  box-shadow: 0px 0px 10px 1px #000000ee;
  border-radius: 5px;
}

.front,
.back {
  background-color: #000000;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border-radius: 5px;
  overflow: hidden;
}

.back {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.back::before {
  position: absolute;
  content: ' ';
  display: block;
  width: 160px;
  height: 160%;
  background: linear-gradient(90deg, transparent, #ff9966, #ff9966, #ff9966, #ff9966, transparent);
  animation: rotation_481 5000ms infinite linear;
}

.back-content {
  position: absolute;
  width: 99%;
  height: 99%;
  background-color: #000000;
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.fcard:hover .fcontent {
  transform: rotateY(180deg);
}

@keyframes rotation_481 {
  0% {
    transform: rotateZ(0deg);
  }

  0% {
    transform: rotateZ(360deg);
  }
}

.front {
  transform: rotateY(180deg);
  color: white;
}

.front .front-content {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.front-content .badge {
  background-color: #00000055;
  padding: 2px 10px;
  border-radius: 10px;
  backdrop-filter: blur(2px);
  width: fit-content;
}

.description {
  box-shadow: 0px 0px 10px 5px #00000088;
  width: 100%;
  padding: 10px;
  background-color: #00000099;
  backdrop-filter: blur(5px);
  border-radius: 5px;
}

/* .title {
  font-size: 11px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
} */

.title p {
  width: 50%;
}

.card-footer {
  color: #ffffff88;
  margin-top: 5px;
  font-size: 8px;
}

.front .img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.circle {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #000;
  position: relative;
  filter: blur(15px);
  animation: floating 2600ms infinite linear;
}

#bottom {
  background-color: #000;
  left: 50px;
  top: 0px;
  width: 150px;
  height: 150px;
  animation-delay: -800ms;
}

#right {
  background-color: #000;
  left: 160px;
  top: -80px;
  width: 30px;
  height: 30px;
  animation-delay: -1800ms;
}

@keyframes floating {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}
.cwof{
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  
}
.cwof-con{
  background: linear-gradient(90deg, transparent, #ff9966, #ff9966, #ff9966, #ff9966, transparent);
  height: fit-content;
    animation: rotation_481 5000ms infinite linear;
    position: absolute;
    top: 0;
    left: 0;
}
.cwof-content{
  position: absolute;
  top: 0;
  left: 0;
}
.sec3{
  /* background-image: url('./img/blob-haikei\ \(1\).png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom; */
  /* height: 100vh; */
  position: relative;
  overflow: hidden;
}
.sec3 .secbg1{
  position: absolute;
  top: 0;
  left: 0;
}

.secbg{
  height: 150vh;
  position: absolute;
  top: 0;
  left: 0;
}
.secbg div{
  position: relative;
  top: 0;
  left: 0;
}
.iris{
  position: absolute;
}
.iris-animation{
  animation: floating2 2600ms infinite linear;
  opacity: 0.6;
}
@keyframes floating2 {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0px);
  }
}
.footer-text{
  text-transform: uppercase;
    font-family: 'Libre Baskerville';
    letter-spacing: 2px;
}
.pcard-img{
  z-index: 3;
  overflow: hidden;
}
.p-sm{
  font-size: small;
}
.crush-button {
  overflow: hidden;
  border: none;
  color: #fff;
  z-index: 1;
  display: flex;
  background: transparent;
  position: relative;
  cursor: pointer;
}

.crush-button img{
  margin: auto;
  align-self: center;
  font-weight: bold;
  text-align: center;
}

.crush-button::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  z-index: -1;
  height: 12%;
  clip-path: polygon(0% 74%,
      4% 75%,
      8% 76%,
      11% 77%,
      15% 78%,
      20% 78%,
      25% 77%,
      32% 77%,
      37% 75%,
      40% 74%,
      43% 74%,
      46% 73%,
      52% 72%,
      57% 72%,
      65% 74%,
      66% 75%,
      71% 78%,
      75% 82%,
      81% 86%,
      83% 88%,
      88% 91%,
      90% 94%,
      94% 96%,
      98% 98%,
      100% 100%,
      82% 100%,
      0 100%);
  background: transparent;
  transition: 0.2s ease;
}
.crush-button:hover img {
  animation: floating 2600ms infinite linear;
}

.crush-button::before {
  position: absolute;
  left :0 ;
  top:0;
  content: "";
  transform: rotate(180deg);
  width: 100%;
  height: 12%;
  transition: 0.2s ease;
  z-index: -1;
  clip-path: polygon(0% 74%,
      4% 75%,
      8% 76%,
      11% 77%,
      15% 78%,
      20% 78%,
      25% 77%,
      32% 77%,
      37% 75%,
      40% 74%,
      43% 74%,
      46% 73%,
      52% 72%,
      57% 72%,
      65% 74%,
      66% 75%,
      71% 78%,
      75% 82%,
      81% 86%,
      83% 88%,
      88% 91%,
      90% 94%,
      94% 96%,
      98% 98%,
      100% 100%,
      82% 100%,
      0 100%);
  background: transparent;
}

.crush-button:hover::after {
  left: 0;
  bottom: -1;
  background: #000;
  clip-path: polygon(0 30%,
      9% 34%,
      7% 39%,
      11% 43%,
      13% 33%,
      17% 30%,
      24% 34%,
      25% 35%,
      30% 31%,
      30% 38%,
      39% 33%,
      35% 43%,
      43% 45%,
      55% 46%,
      65% 74%,
      67% 66%,
      81% 57%,
      75% 82%,
      81% 86%,
      83% 88%,
      88% 91%,
      90% 94%,
      94% 96%,
      98% 98%,
      100% 100%,
      82% 100%,
      0 100%);
  height: 80%;
}

.crush-button:hover::before {
  left: 0;
  top: -1;
  background: #000;
  clip-path: polygon(0 30%,
      9% 34%,
      7% 39%,
      11% 43%,
      13% 33%,
      17% 30%,
      24% 34%,
      25% 35%,
      30% 31%,
      30% 38%,
      39% 33%,
      35% 43%,
      43% 45%,
      55% 46%,
      65% 74%,
      67% 66%,
      81% 57%,
      75% 82%,
      81% 86%,
      83% 88%,
      88% 91%,
      90% 94%,
      94% 96%,
      98% 98%,
      100% 100%,
      82% 100%,
      0 100%);
  height: 80%;
}
.hide{
  display: none;
}
.main {
  display: flex;
  z-index: 4;
  flex-direction: column;
  gap: 0.5em;
}

.up {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.down {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.card1 {
  width: 90px;
  height: 90px;
  outline: none;
  border: none;
  background: white;
  border-radius: 90px 5px 5px 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: .2s ease-in-out;
}

.instagram {
  margin-top: 1.5em;
  margin-left: 1.2em;
  fill: #cc39a4;
}

.card2 {
  width: 90px;
  height: 90px;
  outline: none;
  border: none;
  background: white;
  border-radius: 5px 90px 5px 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: .2s ease-in-out;
}

.facebook {
  margin-top: 1.5em;
  margin-left: -.9em;
  fill: #03A9F4;
}

.card3 {
  width: 90px;
  height: 90px;
  outline: none;
  border: none;
  background: white;
  border-radius: 5px 5px 5px 90px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: .2s ease-in-out;
}

.whatsapp {
  margin-top: -.6em;
  margin-left: 1.2em;
  fill: #00ff00;
}

.card4 {
  width: 90px;
  height: 90px;
  outline: none;
  border: none;
  background: white;
  border-radius: 5px 5px 90px 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: .2s ease-in-out;
}

.gmail {
  margin-top: -.9em;
  margin-left: -1.2em;
  fill: #f14336;
}

.card1:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #cc39a4;
}

.card1:hover .instagram {
  fill: white;
}

.card2:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #1877f2;
}

.card2:hover .facebook {
  fill: white;
}

.card3:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #00ff00;
}

.card3:hover .whatsapp {
  fill: white;
}

.card4:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #FF0004;
}

.card4:hover .gmail {
  fill: white;
}
.inputbox {
  position: relative;
}

.inputbox input {
  position: relative;
  width: 100%;
  padding: 20px 10px 10px;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  color: #23242a;
  font-size: 1em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  z-index: 10;
}

.inputbox span {
  position: absolute;
  left: 0;
  padding: 20px 10px 10px;
  font-size: 1em;
  color: #8f8f8f;
  letter-spacing: 00.05em;
  transition: 0.5s;
  pointer-events: none;
}

.inputbox input:valid~span,
.inputbox input:focus~span {
  color: #AAC7D8;
  transform: translateX(-10px) translateY(-34px);
  font-size: 0, 75em;
}

.inputbox i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #AAC7D8;
  border-radius: 4px;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}

.inputbox input:valid~i,
.inputbox input:focus~i {
  height: 44px;
}
.socket {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%;
  margin-left: -100px;
  top: 50%;
  margin-top: -100px;
}

.hex-brick {
  background: #000000;
  width: 30px;
  height: 17px;
  position: absolute;
  top: 5px;
  animation-name: fade00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  -webkit-animation-name: fade00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
}

.h2 {
  transform: rotate(60deg);
  -webkit-transform: rotate(60deg);
}

.h3 {
  transform: rotate(-60deg);
  -webkit-transform: rotate(-60deg);
}

.gel {
  height: 30px;
  width: 30px;
  transition: all .3s;
  -webkit-transition: all .3s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.center-gel {
  margin-left: -15px;
  margin-top: -15px;
  animation-name: pulse00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  -webkit-animation-name: pulse00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
}

.c1 {
  margin-left: -47px;
  margin-top: -15px;
}

.c2 {
  margin-left: -31px;
  margin-top: -43px;
}

.c3 {
  margin-left: 1px;
  margin-top: -43px;
}

.c4 {
  margin-left: 17px;
  margin-top: -15px;
}

.c5 {
  margin-left: -31px;
  margin-top: 13px;
}

.c6 {
  margin-left: 1px;
  margin-top: 13px;
}

.c7 {
  margin-left: -63px;
  margin-top: -43px;
}

.c8 {
  margin-left: 33px;
  margin-top: -43px;
}

.c9 {
  margin-left: -15px;
  margin-top: 41px;
}

.c10 {
  margin-left: -63px;
  margin-top: 13px;
}

.c11 {
  margin-left: 33px;
  margin-top: 13px;
}

.c12 {
  margin-left: -15px;
  margin-top: -71px;
}

.c13 {
  margin-left: -47px;
  margin-top: -71px;
}

.c14 {
  margin-left: 17px;
  margin-top: -71px;
}

.c15 {
  margin-left: -47px;
  margin-top: 41px;
}

.c16 {
  margin-left: 17px;
  margin-top: 41px;
}

.c17 {
  margin-left: -79px;
  margin-top: -15px;
}

.c18 {
  margin-left: 49px;
  margin-top: -15px;
}

.c19 {
  margin-left: -63px;
  margin-top: -99px;
}

.c20 {
  margin-left: 33px;
  margin-top: -99px;
}

.c21 {
  margin-left: 1px;
  margin-top: -99px;
}

.c22 {
  margin-left: -31px;
  margin-top: -99px;
}

.c23 {
  margin-left: -63px;
  margin-top: 69px;
}

.c24 {
  margin-left: 33px;
  margin-top: 69px;
}

.c25 {
  margin-left: 1px;
  margin-top: 69px;
}

.c26 {
  margin-left: -31px;
  margin-top: 69px;
}

.c27 {
  margin-left: -79px;
  margin-top: -15px;
}

.c28 {
  margin-left: -95px;
  margin-top: -43px;
}

.c29 {
  margin-left: -95px;
  margin-top: 13px;
}

.c30 {
  margin-left: 49px;
  margin-top: 41px;
}

.c31 {
  margin-left: -79px;
  margin-top: -71px;
}

.c32 {
  margin-left: -111px;
  margin-top: -15px;
}

.c33 {
  margin-left: 65px;
  margin-top: -43px;
}

.c34 {
  margin-left: 65px;
  margin-top: 13px;
}

.c35 {
  margin-left: -79px;
  margin-top: 41px;
}

.c36 {
  margin-left: 49px;
  margin-top: -71px;
}

.c37 {
  margin-left: 81px;
  margin-top: -15px;
}

.r1 {
  animation-name: pulse00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .2s;
  -webkit-animation-name: pulse00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .2s;
}

.r2 {
  animation-name: pulse00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .4s;
  -webkit-animation-name: pulse00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .4s;
}

.r3 {
  animation-name: pulse00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .6s;
  -webkit-animation-name: pulse00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .6s;
}

.r1>.hex-brick {
  animation-name: fade00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .2s;
  -webkit-animation-name: fade00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .2s;
}

.r2>.hex-brick {
  animation-name: fade00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .4s;
  -webkit-animation-name: fade00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .4s;
}

.r3>.hex-brick {
  animation-name: fade00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .6s;
  -webkit-animation-name: fade00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .6s;
}

@keyframes pulse00 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.01);
    transform: scale(0.01);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes fade00 {
  0% {
    background: #252525;
  }

  50% {
    background: #000000;
  }

  100% {
    background: #353535;
  }
}
.card2 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e7e7e7;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
  height: 50px;
  width: 200px;
}

.card2::before,
.card2::after {
  position: absolute;
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  transition: 0.25s linear;
  z-index: 1;
}

.card2::before {
  content: "";
  left: 0;
  justify-content: flex-end;
  background-color: #C4982A;
}

.card2::after {
  content: "";
  right: 0;
  justify-content: flex-start;
  background-color: #C4982A;
}

.card2:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.card2:hover span {
  opacity: 0;
  z-index: -3;
}

.card2:hover::before {
  opacity: 0.5;
  transform: translateY(-100%);
}

.card2:hover::after {
  opacity: 0.5;
  transform: translateY(100%);
}

.card2 span {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: whitesmoke;
  font-family: 'Fira Mono', monospace;
  font-size: 24px;
  font-weight: 700;
  opacity: 1;
  transition: opacity 0.25s;
  z-index: 2;
}

.card2 .social-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 100%;
  color: whitesmoke;
  font-size: 24px;
  text-decoration: none;
  transition: 0.25s;
}

.card2 .social-link svg {
  text-shadow: 1px 1px #c4982a97;
  transform: scale(1);
}

.card2 .social-link:hover {
  background-color: rgba(249, 244, 255, 0.774);
  animation: bounce_613 0.4s linear;
}

@keyframes bounce_613 {
  40% {
    transform: scale(1.4);
  }

  60% {
    transform: scale(0.8);
  }

  80% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}