body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#29353C !important;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.video-background {
  position: fixed;
  overflow: hidden;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  opacity: 80%;
}
/* This is the overall scrollbar track */
::-webkit-scrollbar {
  width: 12px;
  /* Adjust the width if needed */
}

/* This is the background of the scrollbar track */
::-webkit-scrollbar-track {
  background: #000;
  /* Change this to your desired color */
}

/* This is the handle (thumb) of the scrollbar */
::-webkit-scrollbar-thumb {
  background: #C4982A;
  /* Change this to your desired color */
  border-radius: 2px;
  /* Optional: add some border radius for rounded corners */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a98322;
  /* Change this to your desired hover color */
}